<template>
  <div id="multi-select">
    <label class="label text-left">{{ $t(inputTagLabel) }}</label>
    <pre>{{ value }}</pre>

    <multiselect
      v-model="inputTagValues"
      :options="existingTags"
      :multiple="true"
      track-by="value"
      label="text"
      :close-on-select="false"
      :placeholder="placeholder"
      :searchable="true"
      @remove="remove"
      @select="select"
    >
      <span
        class="checkbox-label"
        slot="option"
        slot-scope="scope"
        v-show="scope.option.value"
      >
        <span class="text-primary bg-primary"></span>
        <input
          class="mr-2 position-absolute"
          type="checkbox"
          v-model="scope.option.value.selected"
        />
        <label class>{{ scope.option.text }}</label>
      </span>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: [
    "inputTagValues",
    "inputTagLabel",
    "validation",
    "errorMessage",
    "existingTags",
    "placeholder",
  ],
  data() {
    return {
      displayTagValues: [],
    };
  },
  methods: {
    select(option) {
      console.log("select", option);
      // option.value.selected = !option.value.selected;
      this.$emit("formInputChange", option);
    },
    remove(option) {
      console.log("remove");
      // option.value.selected = false;
      this.$emit("tagRemovedChange", option);
    },
  },
};
</script>
<style>
.label {
  font-family: "proxima-nova", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #13285b;
  float: left;
  margin-top: 25px;
}
::placeholder {
  color: #13285b;
  opacity: 0.3;
}
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom: 10px !important;
  padding-bottom: -55px !important;
}
.multiselect--active:not(.multiselect--above) .multiselect__input {
  border-radius: 0 !important;
  display: inline-block;
  width: calc(100% + 32px) !important;
  margin-bottom: 0rem !important;
  min-height: 30px !important;
}
.multiselect--active:not(.multiselect--below) .multiselect__tags {
  border-bottom: 10px !important;
}
.multiselect--active:not(.multiselect--below) .multiselect__input {
  border-radius: 0 !important;
  display: inline-block;
  width: calc(100% - 8px) !important;
  margin-bottom: 5px !important;
  min-height: 30px !important;
  margin-top: 10px !important;
}
.multiselect__content {
  border-bottom: none !important;
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:before,
.multiselect__spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 75%;
  min-height: 38px;
  text-align: left;
  color: #35495e !important;
  margin-top: 0px;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}
.multiselect {
  box-sizing: border-box;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: 0px 3px 12px #ccc;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active:not(.multiselect--below) .multiselect__current,
.multiselect--active:not(.multiselect--below) .multiselect__input,
.multiselect--active:not(.multiselect--below) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotateZ(180deg) translateY(16px);
  margin-top: 5px;
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect--below.multiselect--active .multiselect__current,
.multiselect--below.multiselect--active .multiselect__input,
.multiselect--below.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none !important;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .multiselect__tag {
      margin-bottom: 3px;
    }
    .multiselect__placeholder {
      margin-bottom: 0 !important;
    }
  }
}

.multiselect__tags {
  min-height: 38px;
  display: block;
  padding: 5px 21px 2px 10px;
  background: #ffffff00;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 7px 26px 4px 10px;
  margin-right: 2px;
  color: #ffffff !important;
  line-height: 1;
  background: #13285b;
  opacity: 0.4;
  min-height: 30px;
  font-size: 16px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 0;
  font-weight: 200;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  /* content: url(../assets/x-close.svg) !important; */
  margin-left: -0.2rem;
}
/* .multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
} */
/* .multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
} */
.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}
.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  right: 2%;
  top: 1px;
  padding: 4px 7px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  /* background: url("../assets/down_arrow.svg") no-repeat; */
  top: 25px;
  color: #999;
  margin-top: -15px;
  border-style: solid;
  border-width: 5px 0px 0 5px;
  border-color: #99999900;
}

.multiselect--active > .multiselect__select {
  top: 35px;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 0px 0 5px;
  border-color: #999999 transparent transparent transparent;
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 3px;
}
/* .multiselect--active .multiselect__placeholder {
  display: none;
} */
#multi-select ::-webkit-scrollbar {
  width: 5px;
}
/* ::-webkit-scrollbar-track {
  background: black;
  border-radius: 3px;
} */

#multi-select ::-webkit-scrollbar-thumb {
  background: #d0d4de;
  border-radius: 3px;
  margin-right: 5px;
  position: absolute;
}

#multi-select ::-webkit-scrollbar-thumb:hover {
  background: #d0d4de;
  border-radius: 3px;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  border: 1px solid #e6e9ec;
  background: #fff;
  width: 101%;
  margin-left: -1px;
  max-height: 240px;
  overflow: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
  box-shadow: 2px 6px 12px #ccc;
  scrollbar-color: #d0d4de;
  scrollbar-width: thin;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #e8e8e8;
  box-shadow: 0px -6px 12px 0 #ccc;
}

.multiselect--above {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
*[dir="rtl"] .multiselect {
  text-align: right;
}
*[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
*[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0px 40px;
}
*[dir="rtl"] .multiselect__content {
  text-align: right;
}
*[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
*[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
*[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
.drop-down {
  position: relative;
  z-index: 55;
  float: right;
  margin-right: 5%;
  margin-top: 13%;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
input[type="checkbox"] {
  visibility: hidden;
}
label {
  cursor: pointer;
}
input[type="checkbox"] + label:before {
  border: 2px solid #e6e9ec;
  border-radius: 6px;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 20px;
  width: 20px;
  margin: 0 10px 0 0;
  padding: 0.05rem;
  vertical-align: top;
}
input[type="checkbox"]:checked + label:before {
  background: #fff;
  color: #1285ff;
  /* content: url("../assets/Vector_2.svg"); */
  text-align: center;
  padding-right: 2px;
  padding-top: 2px;
}
input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
</style>
<style scoped>
.custom-control-label::after {
  background-color: none !important;
  width: 1.1rem !important;
  margin-top: 0.4rem !important;
  margin-left: -1.4rem !important;
}

input[type="checkbox"]:checked + label:before {
  background: #fff;
  color: #1285ff;
  content: url("../assets/Vector_2.svg");
  text-align: center;
  padding-right: 3px;
  padding-top: 2px;
}
</style>
