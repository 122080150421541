<template>
  <div class="pt-3">
    <div class="channel">
      <div class="d-flex justify-content-between">
        <p style="color: #090909; font-weight: 600" v-if="addEditDiff == 'new'">Add New Channel</p>
        <p style="color: #090909; font-weight: 600" v-else>Edit Channel</p>
        <div style="cursor: pointer">
          <img src="../../assets/SVG/Back.svg" width="60px" @click="backToChannelType(addEditDiff)" />
        </div>
      </div>
      <w-alert
        v-if="alertState.isAlert"
        :alert="alertState.alert"
        :alertMsg="alertState.alertMsg"
        @closeAlert="alertState.isAlert = false"
      />

      <b-card class="edit-add-form">
        <div class="mt-3 amazon-api-detail">
          <div class="row edit-user-filter">
            <div class="col-lg-3 col-md-3 col-12 channel-input">
              <!-- <label class="mb-1 p-0">Channel Name</label> -->
              <b-input-group :append="marketPlaceName">
                <w-text-input
                  :fieldType="'text'"
                  :disabled="false"
                  :defaultValue="channelName"
                  :labelStyle="'register-label'"
                  @textInputChange="onChangeChannelName($event)"
                  :labelName="'Channel Name'"
                />
              </b-input-group>
            </div>

            <div class="col-lg-3 col-md-3 col-12">
              <label class="mb-1 p-0">Status</label> <img class="info-icon ml-1 mr-4" src="../../assets/info-icon.png" v-b-popover.hover.top="tooltips.status">
              <w-dropdown
                :label-text="''"
                :labelId="'channel-status'"
                :selectedValue="channelStatus"
                :selectOption="statusList"
                @selectedOption="getStatussList($event)"
              />
            </div>

            <!-- <div class="col-lg-3 col-md-3 col-12">
            <label class="mb-1 p-0">Link Warehouse(s)</label>
            <w-multi-select-tag
              style="width:350px"
              :inputTagValues="warehouseIds"
              :existingTags="warehouseList"
              @formInputChange="onMultiSelectWareHouse($event)"
              @tagRemovedChange="tagRemoved($event)"
              slot-scope=""
            />
          </div> -->
            <div class="col-lg-3 col-md-3 col-12 link-warehouse">
              <TreeDropdown
                :labelText="'Link Warehouse(s)'"
                class="mb-0"
                :rootOptions="warehouseList"
                :selectedValue="warehouseIds"
                @selectedField="onMultiSelectWareHouse($event)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <label>Product Sync</label> <img class="info-icon ml-1 mr-4" src="../../assets/info-icon.png" v-b-popover.hover.bottom="tooltips.productSync">
              <b-form-checkbox
                checked="marketplaceFulfilledEnabled"
                v-model="marketplaceFulfilledEnabled"
                name="check-button"
                switch
                buttons
                button-variant="success"
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <label>Inventory Sync</label> <img class="info-icon ml-1 mr-4" src="../../assets/info-icon.png" v-b-popover.hover.bottom="tooltips.inventorySync">
              <b-form-checkbox
                v-model="inventorySyncEnabled"
                name="check-button"
                switch
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <label>Order Sync</label> <img class="info-icon ml-1 mr-4" src="../../assets/info-icon.png" v-b-popover.hover.bottom="tooltips.orderSync">
              <b-form-checkbox
                v-model="orderSyncEnabled"
                name="check-button"
                switch
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <label>Remittance Sync</label> <img class="info-icon ml-1 mr-4" src="../../assets/info-icon.png" v-b-popover.hover.bottom="tooltips.remittanceSync">
              <b-form-checkbox
                v-model="priceSyncEnabled"
                name="check-button"
                switch
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
          </div>

          <div class="row remmitance-datepicker">
            <!-- <div class="col-lg-3 col-md-3 col-12">
              <label class="pr-4">Auto Schedulable</label>
              <b-form-checkbox
                checked="autoSchedulable"
                v-model="autoSchedulable"
                name="check-button"
                switch
                buttons
                button-variant="success"
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <label>Auto Schedule</label>
              <date-picker
                v-model="selectedAutoScheduleDate"
                class="ml-2"
                id="auto-schedule"
                :lang="'en'"
                range
                confirm
                :shortcuts="[]"
                @clear="clearEarlistPickupSlot($event)"
                @confirm="getEarlistPickupSlot($event)"
              ></date-picker>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <label class="pr-4">Earliest Pickup Slot</label>
              <b-form-checkbox
                checked="earlistPickupSlot"
                v-model="earlistPickupSlot"
                name="check-button"
                switch
                buttons
                button-variant="success"
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <label class="tree-label">Email ID</label>
              <w-text-input
                :fieldType="'email'"
                :disabled="false"
                :defaultValue="emailId"
                :validation="'regex_Email'"
                :labelStyle="'register-label'"
                :fieldName="'Email'"
                @textInputChange="geEmailID($event)"
                :labelName="'Email ID'"
              />
            </div> -->
          </div>
         <!-- 
          <div class="row remmitance-datepicker">
            <div class="col-lg-3 col-md-3 col-12">
              <label>Auto Scheduling Time</label>
              <date-picker
                id="auto-scheduling-time"
                v-model="autoSchedulingTime"
                lang="en"
                format="HH"
                type="time"
                :hour-options="hours"
                :minute-step="61"
                placeholder="Select Time  "
              ></date-picker>
            </div>
            <div class="d-flex align-items-end col-lg-3 col-md-3 col-12">
              <div class="mb-2" @click="showSchedulingTime">
                <img src="../../assets/add.png">
                <span class="ml-2 scheduling-time">Add a Scheduling Time</span>
              </div>
            </div>
          </div> -->

          <div class="row remmitance-datepicker">
            <div class="col-lg-3 col-md-3 col-12">
              <label>Sync Start Date</label> <img class="info-icon ml-1 mr-4" src="../../assets/info-icon.png" v-b-popover.hover.top="tooltips.syncStartDate">
              <date-picker
                id="remittance-startDate"
                v-model="remittanceStartDate"
                :lang="'en'"
                valuetype="format"
                confirm
                @clear="clearRemittanceDate($event)"
                @confirm="selectRemittanceDate($event)"
                :disabled="isRemittanceDate"
              ></date-picker>
            </div>
          </div>

          <!-- <div class="row pt-3">
            <p class="api-details-heading pl-3">Amazon API Details</p>
            <img :src="channelImageUrl" class="amazon-rightImage" />
          </div> -->
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Seller ID:</label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="sellerId"
                  :labelStyle="'register-label'"
                  @textInputChange="getSellerId($event)"
                  :labelName="'Seller ID:'"
                  :labelTooltip="tooltips.sellerId"
                  v-if="marketPlaceName == '-amazon.in'"
                />
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :labelName="'API Key:'"
                  :labelStyle="'register-label'"
                  :defaultValue="apiKeys"
                  @textInputChange="getShopifyApiKeys($event)"
                  v-if="marketPlaceName == '-shopify.com'"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">MWS Token:</label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :defaultValue="authToken"
                  :labelStyle="'register-label'"
                  @textInputChange="getAuthToken($event)"
                  :labelName="'MWS Token:'"
                  :labelTooltip="tooltips.merchantToken"
                  v-if="marketPlaceName == '-amazon.in'"
                />
                <w-text-input
                  :fieldType="'password'"
                  class="col-md-7"
                  :labelName="'Password:'"
                  :labelStyle="'register-label'"
                  :defaultValue="shopifyPassword"
                  @textInputChange="getShopifyPassword($event)"
                  v-if="marketPlaceName == '-shopify.com'"
                />
              </div>
            </div>
          </div>
          <!-- <div class="row">
          <label class="d-flex align-items-center col-md-2 mb-0 mt-2">Marketplace:</label>
          <w-text-input
            :fieldType="'text'"
            class="col-md-5"
            :defaultValue="marketplaceId"
            :disabled="true"
            :labelStyle="'register-label'"
            @textInputChange="getmarketplaceId($event)"
          />
        </div> -->
          
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Fulfilment latency: <span class="warning_icon">!</span></label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="fulfillmentLatency"
                  :labelStyle="'register-label'"
                  @textInputChange="getFulfilmentLatency($event)"
                  :labelName="'Fulfilment latency:'"
                  :labelTooltip="tooltips.fulfilmentLatency"
                  v-if="marketPlaceName == '-amazon.in'"
                />
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :labelStyle="'register-label'"
                  :labelName="'Store Name:'"
                  :defaultValue="shopifyStoreName"
                  @textInputChange="getShopifyStoreName($event)"
                  v-if="marketPlaceName == '-shopify.com'"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Access Key Id: <span class="warning_icon">!</span></label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="accessKeyId"
                  :labelStyle="'register-label'"
                  @textInputChange="getAccessKeyId($event)"
                  :labelName="'Access Key Id:'"
                  :labelTooltip="tooltips.accessKeyId"
                  v-if="marketPlaceName == '-amazon.in'"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Secret Key: <span class="warning_icon">!</span></label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="secretKey"
                  :labelStyle="'register-label'"
                  @textInputChange="getSecretKey($event)"
                  :labelName="'Secret Key:'"
                  :labelTooltip="tooltips.secretKey"
                  v-if="marketPlaceName == '-amazon.in'"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
            </div>
          </div>

          <div class="row" v-if="marketPlaceName == '-woocommerce.com'">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Secret Key: <span class="warning_icon">!</span></label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="wcConsumerKey"
                  :labelStyle="'register-label'"
                  @textInputChange="wcConsumerKey = $event"
                  :labelName="'Consumer Key:'"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Secret Key: <span class="warning_icon">!</span></label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="wcConsumerSecret"
                  :labelStyle="'register-label'"
                  @textInputChange="wcConsumerSecret = $event"
                  :labelName="'Consumer Secret:'"
                />
              </div>
            </div>
          </div>
          <div class="row" v-if="marketPlaceName == '-woocommerce.com'">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <!-- <label class="d-flex align-items-center col-md-3 mb-0">Secret Key: <span class="warning_icon">!</span></label> -->
                <w-text-input
                  :fieldType="'text'"
                  class="col-md-7"
                  :disabled="false"
                  :defaultValue="wcStoreDomain"
                  :labelStyle="'register-label'"
                  @textInputChange="wcStoreDomain = $event"
                  :labelName="'Store Domain:'"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
            </div>
          </div>

        </div>

        <div class="text-center my-3 mt-5 mb-4">
          <button
            @click="showConfirmModal()"
            :disabled="isSaveBtnDisabled"
            class="save-edit mr-5"
            id="save-channel"
          >
            <span v-if="addEditDiff == 'new'">Add</span>
            <span v-else>Update</span>
          </button>
          <button 
            class="cancel-edit mr-5" 
            @click="goToViewChannel()"
            id="cancel-channel"
          >
            Cancel
          </button>
        </div>
      </b-card>

      <!-- modal for error handling -->
      <Modal
        :trigger="alert || isSchedulingTime"
        :title="'HectoCommerce'"
        :centered="true"
        :showModalTable="false"
        :dontCloseWhenClickedOutside="true"
        :dontCloseWhenEscapePressed="true"
        :dontShowCloseIcon="true"
        :modalbuttonStyle="true"
        :alert="alert"
        :alertMsg="alertMsg"
        :alertType="alertType"
        :isConfirmAlert="isConfirmAlert"
        @eventClose="closeModal()"
        @confirmOkEvent="confirmOk"
        :isSchedulingTime="isSchedulingTime"
        @saveSchedulingTimeEvent="saveSchedulingTime($event)"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import DatePicker from "vue2-datepicker";
import MultipleSelectDropdown from "../../widgets/MultipleSelectDropdown.vue";
import { EventBus } from "@/main.js";
import Alert from "../../widgets/Alert.vue";
import Vue from "vue";
import Modal from "../../widgets/ModalWidget.vue";
import TreeDropdown from "../../widgets/TreeDropdown.vue";
// import { ChannelService } from "../../Service/ChannelService";
// const channelservice = new ChannelService();

import {
  getChannelProfileById,
  getChannelsStatus,
  getChannelWarehouse,
  addNewChannel,
  updateChannelDetails,
  getImageUrlByID,
} from "../../Service/ChannelServiceApi";

export default {
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    "w-multi-select-tag": MultipleSelectDropdown,
    DatePicker,
    Modal,
    "w-alert": Alert,
    TreeDropdown,
  },
  // props:["channelData"],  // props coming from route params
  data() {
    return {
      tooltips: {
        productSync: 'Enables the product to be loaded on to the system.',
        inventorySync: 'Enables inventory to be loaded into the system',
        orderSync: 'Enable the orders to get uploaded in to the system. Allows the order processing. Mandatory field to enable the order processing.', 
        remittanceSync:	'Enables the remittance details to be loaded in to the system.',
        syncStartDate: 'The date from which the order details are loaded in to the system.',
        merchantToken: `Your merchant token is a unique merchant identifier that is used in Seller Desktop, AMTU, 3rd Party applications, and the XML feeds. It is used to match up products that you upload with your merchant account.
                        <br>To view your Merchant Token, complete the following steps:
                        <ul style="list-style: inside">
                        <li>From the Seller Account Information page, go to the Business Information section.</li>
                        <li>Select Merchant Token.</li> 
                        <li>The token is displayed at the bottom of the page.</li>
                        </ul>`,                                           
        accessKeyId: 'A 20-character alphanumeric identifier.',
        secretKey: 'A 40-character password linked to your AWS Access Key.',
        status:	'Indicates whether the key is enabled or disabled.',
        fulfilmentLatency: 'The time taken to fulfil the order, usualy 2 days'
      },
      channelData: "",
      companyID: sessionStorage.getItem("companyIds"),
      // for error handling message
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      isSchedulingTime:false,

      alertState: {
        alert: "",
        alertMsg: "",
        isAlert: "",
      },
      apiKeys:'',
      shopifyPassword:'',
      shopifyStoreName:'',
      channelId: 0,
      channelName: "",
      channelStatus: "",
      marketplaceId: "",
      fulfillmentLatency: "",
      sellerId: "",
      authToken: "",
      fromDate: "",
      // companyId: 7,
      inventorySyncEnabled: false,
      marketplaceFulfilledEnabled: false,
      orderSyncEnabled: false,
      priceSyncEnabled: false,
      // autoSchedulable:false,
      // earlistPickupSlot:false,
      // selectedAutoScheduleDate:"",
      // autoSchedulingTime:"",
      // emailId:"",
      accessKeyId: "",
      secretKey: "",
      statusList: [],
      warehouseList: [],
      warehouseIds: [],
      warehouse: "",
      // currentDate:"",
      remittanceStartDate: null,
      isRemittanceDate: false,
      channelImageUrl: "",
      marketPlaceName:"",
      wcConsumerKey:"",
      wcConsumerSecret:"",
      wcStoreDomain:""
    };
  },
  mounted() {
    //   EventBus.$on("updateWarehouse", (data) => {
    //     this.loadWarehouse(data)
    // })
    // channelIdbyParams = this.$route.query.id;
    this.channelData = JSON.parse(sessionStorage.getItem("editDetail"));

    if (this.$route.params.channelId == "new") {
      this.getWarehouseDropdown(this.$route.query.id);
      this.getImageUrlByParams(this.$route.query.id);
    } else {
      this.getWarehouseDropdown(this.channelData.marketID);
      this.getImageUrlByParams(this.channelData.marketID);
    }

    this.getStatusList();

    this.marketplaceId = this.$route.query.id;
    this.addEditDiff = this.$route.params.channelId;
  },

  beforeUpdate() {
    this.isSaveBtnDisabled = ![
      this.channelName,
      // this.companyID,
      // this.channelStatus,
      //  this.marketplaceId,
      this.remittanceStartDate,
      this.channelStatus,
      this.warehouseIds.length,
    ].every(Boolean);
  },

  methods: {
    // back to channel page
    backToChannelType(tab) {
      if(tab == 'new'){
        this.$router.push("/ChannelType");
      }else{
        this.$router.push("/ViewChannel");
      }
      
    },

    getImageUrlByParams(id) {
      getImageUrlByID(id)
        .then((res) => {
          this.channelImageUrl = res.marketplaceLogoUrl;
          this.marketPlaceName = `-${res.marketplaceName}`;
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    onChangeChannelName(event) {
      this.channelName = event;
    },
    getStatussList(event) {
      this.channelStatus = event;
    },
    // get user email
    // geEmailID(event) {
    //   this.emailId = event;
    // },

    // getEarlistPickupSlot() {
    //   this.startDate = moment(this.selectedAutoScheduleDate[0]).valueOf();
    //   this.endDate = moment(this.selectedAutoScheduleDate[1]).valueOf();
    // },

    // clearEarlistPickupSlot() {
    //   this.startDate = "";
    //   this.endDate = "";
    // },

    // getmarketplaceId(event) {
    //   this.marketplaceId = event;
    // },
    getAccessKeyId(event) {
      this.accessKeyId = event;
    },
    getSecretKey(event) {
      this.secretKey = event;
    },

    getFulfilmentLatency(event) {
      this.fulfillmentLatency = event;
    },
    getAuthToken(event) {
      this.authToken = event;
    },
    getSellerId(event) {
      this.sellerId = event;
    },
    // data for shopify
    getShopifyApiKeys(event){
      this.apiKeys= event
    },
    getShopifyPassword(event){
      this.shopifyPassword=event
    },
    getShopifyStoreName(event){
      this.shopifyStoreName=event
    },

    goToViewChannel() {
      // this.$emit("goToViewChannel");
      this.$router.push({ path: "/ViewChannel" });
    },
    clearRemittanceDate() {
      this.remittanceStartDate = "";
    },
    selectRemittanceDate() {
      this.remittanceStartDate = moment(this.remittanceStartDate).valueOf();
    },
    onMultiSelectWareHouse(event) {
      this.warehouseIds = event;
    },

    getWarehouseDropdown(marketID) {
      this.warehouseList = [];
      getChannelWarehouse(this.companyID, marketID)
        .then((res) => {
          if (this.$route.params.channelId !== "new") {
            this.loadChannel(this.channelData.channelID);
          }
          res.map((element) => {
            this.warehouseList.push({
              id: element.warehouseId,
              label: element.warehouseName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getStatusList() {
      this.statusList = [];
      var status = [];
      getChannelsStatus()
        .then((res) => {
          status = res;
          status.map((element) => {
            this.statusList.push({
              value: element,
              text: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // load channel detail by id when we edit channel
    loadChannel(channelId) {
      this.isRemittanceDate = true;
      this.channelId = channelId;
      getChannelProfileById(channelId, this.companyID)
        .then((data) => {
          this.data = data;
          // this.channelName = data.channel.channelName;
          this.channelName = data.channel.channelName.includes(this.marketPlaceName) ? data.channel.channelName.slice(0, -this.marketPlaceName.length) : data.channel.channelName;
          this.marketplaceId = data.channel.marketplaceId;
          this.channelStatus = data.channel.channelStatus;
          this.sellerId = data.amazonChannel?.sellerId;
          this.authToken = data.amazonChannel?.authToken;
          this.marketplaceFulfilledEnabled =
            data.channel.marketplaceFulfilledEnabled;
          this.fulfillmentLatency = data.channel.fulfillmentLatency;
          (this.secretKey = data.amazonChannel?.secretKey),
            (this.accessKeyId = data.amazonChannel?.accessKeyId),
            (this.orderSyncEnabled = data.channel.orderSyncEnabled);
          this.priceSyncEnabled = data.channel.remittanceSyncEnabled;
          this.inventorySyncEnabled = data.channel.inventorySyncEnabled;
          this.remittanceStartDate = data.channel.remittanceStartDate;
          this.warehouseIds = data.warehouseIds;

          // for shopify autofill
          this.apiKeys = data.shopifyChannelDto?.apiKey
          this.shopifyPassword = data.shopifyChannelDto?.password
          this.shopifyStoreName = data.shopifyChannelDto?.storeName

          this.wcConsumerKey = data.wcChannelDto?.consumerKey;
          this.wcConsumerSecret = data.wcChannelDto?.consumerSecret;
          this.wcStoreDomain = data.wcChannelDto?.storeDomain;

          // this.warehouseIds =  data.warehouseIds.map((id)=>{
          //   return this.warehouseList.find((el)=> el.value === id) || {};
          // });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // submit add new channel and edited channel based on condition
    submitChannnel() {
      const isCreateType = this.$route.params.channelId === "new";
      const marketplace = this.marketPlaceName === '-woocommerce.com' ? 'wc' : this.marketPlaceName === '-shopify.com' ? 'shopify' : 'amazon';
      if (isCreateType) {
        let channel = {
          // channelName: this.channelName,
          channelName: `${this.channelName}${this.marketPlaceName}`,
          // companyId: this.companyID,
          channelStatus: this.channelStatus,
          marketplaceId: this.marketplaceId,
          fulfillmentLatency: Number(this.fulfillmentLatency),
          inventorySyncEnabled: this.inventorySyncEnabled,
          // isMarketplaceFulfilledEnabled: this.marketplaceFulfilledEnabled,
          marketplaceFulfilledEnabled: this.marketplaceFulfilledEnabled,
          // isOrderSyncEnabled: this.orderSyncEnabled,
          orderSyncEnabled: this.orderSyncEnabled,
          // isPriceSyncEnabled: this.priceSyncEnabled,
          remittanceSyncEnabled: this.priceSyncEnabled,
          remittanceStartDate: this.remittanceStartDate,
          emailId: "amazon@amazon.com",
        };
        let amazonChannel = {
          authToken: this.authToken,
          sellerId: this.sellerId,
          // marketplaceId: this.marketplaceId,
          accessKeyId: this.accessKeyId,
          // storePrefix:"",
          secretKey: this.secretKey,
        };

        let shopifyChannelDto={
          apiKey: this.apiKeys,
          password: this.shopifyPassword ,
          storeName : this.shopifyStoreName
        };

        let wcChannelDto={
          consumerKey: this.wcConsumerKey,
          consumerSecret: this.wcConsumerSecret ,
          storeDomain : this.wcStoreDomain
        }

        let newChannel = {
          channel,
          warehouseIds: this.warehouseIds,
          saveRemittanceStartDate: this.remittanceStartDate,
        };
        
        // Add the channel specific data dynamically
        if(this.marketPlaceName === '-woocommerce.com') newChannel.wcChannelDto = wcChannelDto;
        if(this.marketPlaceName === '-amazon.in') newChannel.amazonChannel = amazonChannel;
        if(this.marketPlaceName === '-shopify.com') newChannel.shopifyChannelDto = shopifyChannelDto;

        addNewChannel(newChannel, marketplace, this.companyID)
          .then((res) => {
            this.alertState.alert = "Success";
            this.alertState.alertMsg = "Channel Details Updated Successfully.";
            this.alertState.isAlert = true;
            setTimeout(() => {
              this.alertState.isAlert = false;
              this.goToViewChannel();
            }, 1000);
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      } else {
        let data = this.data;
        let channel = {
          amazonChannel: data.channel.amazonChannel,
          channelId: this.channelId,
          // channelName: this.channelName,
          channelName: `${this.channelName}${this.marketPlaceName}`,
          channelStatus: this.channelStatus,
          companyId: data.channel.companyId,
          createdOn: data.channel.createdOn,
          emailId: data.channel.emailId,
          fulfillmentLatency: this.fulfillmentLatency,
          orderSyncEnabled: this.orderSyncEnabled,
          inventorySyncEnabled: this.inventorySyncEnabled,
          // priceSyncEnabled: this.priceSyncEnabled,
          remittanceSyncEnabled:this.priceSyncEnabled,
          marketplaceFulfilledEnabled: this.marketplaceFulfilledEnabled,
          marketplaceId: data.channel.marketplaceId,
          password: data.channel.password,
          remittanceStartDate: this.remittanceStartDate,
          // username: data.channel.username,
          // username: this.channelName,
          username: `${this.channelName}${this.marketPlaceName}`,
        };
        let amazonChannel = {
          amazonChannelId: data.amazonChannel.amazonChannelId,
          // accessKeyId: data.amazonChannel.accessKeyId, //"AKIAJZOPL7HTAFZU3DGA",
          accessKeyId: this.accessKeyId,
          authToken: this.authToken,
          channelId: this.channelId,
          // secretKey: data.amazonChannel.secretKey,
          secretKey: this.secretKey,
          marketplaceId: data.amazonChannel.marketplaceId,
          // sellerId: data.amazonChannel.sellerId,
          sellerId: this.sellerId,
          storePrefix: data.amazonChannel.storePrefix,
        };
        let shopifyChannelDto={
          apiKey: this.apiKeys,
          password: this.shopifyPassword ,
          storeName : this.shopifyStoreName,
          shopifyChannelId: data.shopifyChannelDto.shopifyChannelId
        };
        let wcChannelDto={
          consumerKey: this.wcConsumerKey,
          consumerSecret: this.wcConsumerSecret ,
          storeDomain : this.wcStoreDomain
        };
        let newChannel = {
          channel,
          warehouseIds: this.warehouseIds,
        };
        
        // Add the channel specific data dynamically
        if(this.marketPlaceName === '-woocommerce.com') newChannel.wcChannelDto = wcChannelDto;
        if(this.marketPlaceName === '-amazon.in') newChannel.amazonChannel = amazonChannel;
        if(this.marketPlaceName === '-shopify.com') newChannel.shopifyChannelDto = shopifyChannelDto;
        
        updateChannelDetails(newChannel, marketplace, this.companyID)
          .then((res) => {
            this.alertState.alert = "Success";
            this.alertState.alertMsg = "Channel Details Updated Successfully.";
            this.alertState.isAlert = true;
            setTimeout(() => {
              this.alertState.isAlert = false;
              this.goToViewChannel();
            }, 1000);
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      }
    },
    // end submit channel

    // showSchedulingTime(){
    //   this.isSchedulingTime=true;
    // },

    saveSchedulingTime(event){
      console.log("pick up time ", event)
    },

    // close confirmation modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
      this.isSchedulingTime=false;
    },
    showConfirmModal() {
      this.alert = true;
      this.alertMsg = "Are you sure want to save ?";
      this.alertType = "";
      this.isConfirmAlert = true;
    },
    confirmOk() {
      this.submitChannnel();
    },
  },
};
</script>

<style scoped>
.channel {
  /* margin: 0 60px; */
  margin: 0rem 55px 40px 60px;
}
.channel-input ::v-deep .input-group{
  flex-wrap: nowrap;
}
.channel-input ::v-deep .input-group-append {
  height: 37px;
  margin-top: 23px;
}
.edit-user-filter{
  align-items: center;
}
.edit-user-filter label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  color: #767676;
}
.edit-user-filter ::v-deep #multi-select pre {
  margin-bottom: 0;
}
.edit-user-filter
  ::v-deep
  #multi-select
  .multiselect
  .multiselect__tags
  .multiselect__tags-wrap
  .multiselect__tag {
  min-height: 22px;
  font-size: 10px;
}
.edit-add-form ::v-deep .lable-name{
  font-size: 12px;
}
.edit-add-form .card-body {
  padding: 0 14px;
}

.edit-add-form .card {
  border: none;
}
.amazon-api-detail label,
.remmitance-datepicker label {
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
}
.amazon-api-detail .warning_icon {
  border: 1px solid #f2943a;
  border-radius: 50%;
  font-size: 7px;
  padding: 1px 4px;
  color: #f2943a;
  vertical-align: middle;
}

/* for check radio filter */
.amazon-api-detail ::v-deep .custom-switch .custom-control-label::before {
  width: 2rem;
  left: -2.15rem;
  background: #f51313;
  border-color: #f51313 !important;
}
.amazon-api-detail ::v-deep input[type="checkbox"] + label:before {
  height: 17px;
}
.amazon-api-detail ::v-deep .custom-switch .custom-control-label::after {
  left: calc(-2.25rem + 5px);
}
.amazon-api-detail
  ::v-deep
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #4bec1c !important;
  border-color: #4bec1c !important;
}
.amazon-api-detail ::v-deep .custom-switch .custom-control-label::after {
  background: #ffffff !important;
  border-color: #fff !important;
}
.amazon-rightImage {
  position: absolute;
  right: 3%;
  top: 40%;
  width: 137px;
  height: 124.5px;
  object-fit: contain;
}
.api-details-heading {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: #363535;
}
.save-edit {
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #2e8cd3;
  color: #fff;
  padding: 4px 50px;
  font-size: 16px;
  font-family: "proxima-nova", sans-serif !important;
}
.cancel-edit {
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #ffffff;
  color: #2e8cd3;
  padding: 4px 45px;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif !important;
}
.my-buttons .active {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
/* .amazon-api-detail ::v-deep .input-group .form-control, */
.amazon-api-detail ::v-deep .input-group .form-control {
  font-size: 14px;
  color: #767676;
}

::v-deep .custom-select,
::v-deep .form-control {
  height: 37px !important;
}
.link-warehouse ::v-deep label {
  margin-bottom: 0 !important;
}
::v-deep.mx-datepicker{
  width: 100%;
}
.scheduling-time{
  color:#2E8CD3; 
  font-size:14px; 
  cursor:pointer;
}
@media screen and (max-width: 768px) {
  .channel {
    margin: 0 10px;
    margin-top: -50px;
  }
  .save-edit {
    width: 100%;
  }
  .cancel-edit {
    width: 100%;
    margin-top: 10px;
  }
}
.info-icon {
  height:15px;
  margin-bottom:1px;
  cursor: default;
}
</style>
